import React from 'react';
import './BrowserUnsupported.css';

export default function BrowserUnsupported() {
  return (
    <p class="browser-unsupported">
      Looks like you need to upgrade your browser to use this app.
    </p>
  );
}
