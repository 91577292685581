import React from 'react';
import './CallMessage.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Rings } from 'react-loader-spinner';

/**
 * Props:
 * - header: string
 * - detail: string
 * - isError: boolean
 */
export default function CallMessage(props) {
  return (
    <div className="optionsW">
      <Rings color="grey" />
      <h2>Finding someone for you to argue with...</h2>
    </div>
  );
}
