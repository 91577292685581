import React from 'react';
import './Username.css';
import { useForm } from "react-hook-form";

/**
 * Props:
 * - disabled: boolean
 * - onClick: () => ()
 */
 export default function Username({ setStage, setNewUser }) {
  const { handleSubmit, register, formState: { errors } } = useForm();
  const onSubmit = async values => {
    const createUserRequest = await fetch('https://hguh3w0k9d.execute-api.us-east-1.amazonaws.com/mind/change', {
      method: 'POST',
      body: JSON.stringify({ action: "newUser", username: values.username }),
    })

    if (createUserRequest.ok) {
      const userInfo = await createUserRequest.json();
      localStorage.setItem("token", userInfo.token);
      localStorage.setItem("username", userInfo.username);
      setNewUser(false);
      setStage('opinionPicker')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <input
        type="username"
        autoComplete="false"
        {...register("username", {
          required: "Required"
        })}
      />

      <button type="submit">Submit</button>
    </form>
  );
};