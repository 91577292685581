import React, { useState } from 'react';
import './OpinionPicker.css';
import { useForm } from "react-hook-form";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Rings } from 'react-loader-spinner';


/**
 * Props:
 * - disabled: boolean
 * - onClick: () => ()
 */
 export default function OpinionPicker({ setStage, joinCall, setOpinionId, setOpinion }) {

  const [opinion, setOpinionn] = useState(false);

  const pick = async (stance) => {
    setOpinionn(true);
    const pickOpinionRequest = await fetch('https://hguh3w0k9d.execute-api.us-east-1.amazonaws.com/mind/change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${localStorage.getItem('token')}`
      }, 
      body: JSON.stringify({ action: "setOpinion", opinion: stance, opinionId: "1" }),
    })

    console.log(await pickOpinionRequest.json());
    if (pickOpinionRequest.ok) {
      setOpinionId('1');
      setOpinionn(stance);
      const findRoomRequest = await fetch('https://hguh3w0k9d.execute-api.us-east-1.amazonaws.com/mind/change', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ action: "argue", opinionId: "1" }),
      })

      const roomInfo = await findRoomRequest.json();


      joinCall(roomInfo.roomId, roomInfo.dailyToken);
      setStage('');
    }
  }


  return (
    <div className="opinions">
      <h1>pineapple on pizza?</h1>
      { !opinion && (
        <div className="optionsW">
          <div className="options">
            <div className="option a" onClick={() => pick('yes')}>
              <img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/114/thumbs-up-sign_1f44d.png" /><p>yes</p>
            </div>
            <div className="option b" onClick={() => pick('no')}>
              <img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/81/thumbs-down-sign_1f44e.png" /><p>no</p>
            </div>
          </div>
        </div>
      )}

      { opinion && (
        <div className="optionsW">
          <Rings color="grey" />
          <h2>Finding someone for you to argue with...</h2>
        </div>
      )}
      
    </div>
  );
};