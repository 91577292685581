import React from 'react';
import './StartButton.css';

/**
 * Props:
 * - disabled: boolean
 * - onClick: () => ()
 */
export default function StartButton(props) {
  return (
    <div className="mainScreen">
          <h1 className="title">Change my Mind</h1>
          <button
            className="start-button"
            disabled={props.disabled}
            onClick={props.onClick}
          >
            start an argument
          </button>
    </div>
  );
}
